// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["D7ST9hiVu"];

const variantClassNames = {D7ST9hiVu: "framer-v-cvz1hc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, schattenDarstellen, screenshot, width, ...props}) => { return {...props, dD1T0e5lE: schattenDarstellen ?? props.dD1T0e5lE ?? true, VTparqvEX: screenshot ?? props.VTparqvEX ?? {src: new URL("assets/1024/7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png", import.meta.url).href, srcSet: `${new URL("assets/512/7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png", import.meta.url).href} 249w, ${new URL("assets/1024/7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png", import.meta.url).href} 499w, ${new URL("assets/2048/7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png", import.meta.url).href} 999w, ${new URL("assets/7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png", import.meta.url).href} 1170w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;schattenDarstellen?: boolean;screenshot?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dD1T0e5lE, VTparqvEX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "D7ST9hiVu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ILYBF", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-cvz1hc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"D7ST9hiVu"} ref={ref} style={{...style}}>{dD1T0e5lE && (<Image background={{alt: "", fit: "fill", intrinsicHeight: 295, intrinsicWidth: 924, pixelHeight: 295, pixelWidth: 924, sizes: "calc(min(420px, 100vw) * 2.6)", src: new URL("assets/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href, srcSet: `${new URL("assets/512/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href} 512w, ${new URL("assets/TJi2VWHuHQaoTGglcGTyiQGrFvo.png", import.meta.url).href} 924w`}} className={"framer-b5a0p"} data-framer-name={"Schatten"} layoutDependency={layoutDependency} layoutId={"YdT4nVIGq"}/>)}<Image background={{alt: "", fit: "fill", sizes: "calc(min(420px, 100vw) * 0.8667)", ...toResponsiveImage(VTparqvEX)}} className={"framer-e9f8s3"} data-framer-name={"Screenshot"} layoutDependency={layoutDependency} layoutId={"VRSCMBBJj"}/><Image background={{alt: "", fit: "fill", intrinsicHeight: 843, intrinsicWidth: 420, pixelHeight: 843, pixelWidth: 420, sizes: "min(420px, 100vw)", src: new URL("assets/JziVQ79NBq4uwuut1tnLZAeUE.png", import.meta.url).href, srcSet: `${new URL("assets/512/JziVQ79NBq4uwuut1tnLZAeUE.png", import.meta.url).href} 255w, ${new URL("assets/JziVQ79NBq4uwuut1tnLZAeUE.png", import.meta.url).href} 420w`}} className={"framer-binbm4"} data-framer-name={"Rahmen"} layoutDependency={layoutDependency} layoutId={"QX3piBciW"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ILYBF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ILYBF .framer-1ef97ea { display: block; }", ".framer-ILYBF .framer-cvz1hc { height: 843px; position: relative; width: 420px; }", ".framer-ILYBF .framer-b5a0p { flex: none; height: 26%; left: calc(55.00000000000003% - 260% / 2); position: absolute; top: calc(97.86476868327404% - 26.097271648873072% / 2); width: 260%; }", ".framer-ILYBF .framer-e9f8s3 { flex: none; height: 89%; left: calc(49.76190476190479% - 86.66666666666667% / 2); position: absolute; top: calc(51.83867141162517% - 89.32384341637011% / 2); width: 87%; }", ".framer-ILYBF .framer-binbm4 { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(49.940688018979856% - 100% / 2); width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 843
 * @framerIntrinsicWidth 420
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dD1T0e5lE":"schattenDarstellen","VTparqvEX":"screenshot"}
 */
const FramerS9tFy6wRL: React.ComponentType<Props> = withCSS(Component, css, "framer-ILYBF") as typeof Component;
export default FramerS9tFy6wRL;

FramerS9tFy6wRL.displayName = "Mockup iPhone Foto";

FramerS9tFy6wRL.defaultProps = {height: 843, width: 420};

addPropertyControls(FramerS9tFy6wRL, {dD1T0e5lE: {defaultValue: true, title: "Schatten darstellen?", type: ControlType.Boolean}, VTparqvEX: {__defaultAssetReference: "data:framer/asset-reference,7osIQ8Rbv7ZUhy4ncYa2bdSNo0.png?originalFilename=Vorgang_iPhone.png&preferredSize=auto", title: "Screenshot", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerS9tFy6wRL, [])